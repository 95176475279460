export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "sale_account",
        label: "매출액",
    },
    {
        field: "sale_cost",
        label: "매출원가",
    },
    {
        field: "sale_totl_prfi",
        label: "매출총이익",
    },
    {
        field: "depr_cost",
        label: "감가상각비",
        hidden: true
    },
    {
        field: "sell_mang",
        label: "판매및관리비",
        hidden: true
    },
    {
        field: "bsop_prti",
        label: "영업이익",
    },
    {
        field: "bsop_non_ernn",
        label: "영업외수익",
        hidden: true
    },
    {
        field: "bsop_non_expn",
        label: "영업외비용",
        hidden: true
    },
    {
        field: "op_prfi",
        label: "경상이익",
    },
    {
        field: "spec_prfi",
        label: "특별이익",
        hidden: true
    },
    {
        field: "spec_loss",
        label: "특별손실",
        hidden: true
    },
    {
        field: "thtr_ntin",
        label: "당기순이익",
    }
]