import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import SimpleTable from 'components/Table/Simple';
import FinancialInfoChart from 'pages/Stock/FinancialInfo/Chart';
import { useRadio } from 'components/Form/Radio/Card';
import { useTickerCombo } from 'containers/Form/TickerCombo';
import axios from 'axios';
import _ from 'lodash';
import { columnInfos as columnInfosBalanceSheet } from 'pages/FSA/BalanceSheet/config';
import { columnInfos as columnInfosIncomeStatement } from 'pages/FSA/IncomeStatement/config';
import { columnInfos as columnInfosFinancialRatio } from 'pages/FSA/FinancialRatio/config';
import { columnInfos as columnInfosProfitRatio } from 'pages/FSA/ProfitRatio/config';
import { columnInfos as columnInfosStabilityRatio } from 'pages/FSA/StabilityRatio/config';
import { columnInfos as columnInfosGrowthRatio } from 'pages/FSA/GrowthRatio/config';
import { columnInfos as columnInfosOtherMajorRatios } from 'pages/FSA/OtherMajorRatios/config';

const StockGeneralTab = ({ ticker, url, columns }) => {
    const [tableData, setTableData] = useState([]);
    const { Radio, selectedItem } = useRadio({ items: RADIO_ITEMS });

    useEffect(() => {
        console.log("Stock Simple Table On Ticker Change", ticker);
        setTableData([]);
        updateData();
    }, [ticker, url])

    const updateData = async () => {
        if (_.isNil(ticker)) {
            return;
        }
        const params = {
            ticker: ticker
        }
        console.log("Stock General Tab Request Params", params)
        const ret = await axios.get(url, { params });
        if (ret.data.length === 0) {
            console.log("[StockSimpleTable] No data found.");
            return;
        }
        const tempTableData = ret.data.map((elm) => (_.omit(elm, ['_id', "createdAt"])));
        const sortedData = _.sortBy(tempTableData, "stac_yymm").reverse();
        setTableData(sortedData);
    }
    // omit ticker and name set hidden true
    const modifiedColumns = columns.map((column) => {
        if (column.field === "ticker" || column.field === "name") {
            return { ...column, hidden: true };
        }
        return column;
    });

    return (
        <div>
            <div className="flex justify-end mt-3">
                <Radio />
            </div>

            {selectedItem.name === "테이블" ?
                <div className="mt-3">
                    <SimpleTable data={tableData} columns={modifiedColumns}></SimpleTable>
                </div>
                :
                <div className="mt-2">
                    <FinancialInfoChart tableData={tableData} columns={modifiedColumns} url={url} />
                </div>
            }
        </div>
    )
}

const RADIO_ITEMS = [
    { name: "테이블" },
    { name: "차트" }
]

export default () => {
    const { Combobox, ticker } = useTickerCombo({ isExistBalanceSheet: true });

    return (
        <Card>
            <div className="p-3">
                <div className="flex justify-between">
                    <h6 className="font-bold font-lg self-center text-nowrap pe-3">재무정보</h6>
                    <Combobox />
                </div>
                <div>
                    <Tabs>
                        <Tab title="대차대조표">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/balance_sheet" columns={columnInfosBalanceSheet} />
                        </Tab>
                        <Tab title="손익계산서">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/income_statement" columns={columnInfosIncomeStatement} />
                        </Tab>
                        <Tab title="재무비율">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/financial_ratio" columns={columnInfosFinancialRatio} />
                        </Tab>
                        <Tab title="수익성비율">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/profit_ratio" columns={columnInfosProfitRatio} />
                        </Tab>
                        <Tab title="안정성비율">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/stability_ratio" columns={columnInfosStabilityRatio} />
                        </Tab>
                        <Tab title="성장성비율">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/growth_ratio" columns={columnInfosGrowthRatio} />
                        </Tab>
                        <Tab title="기타주요비율">
                            <StockGeneralTab ticker={ticker} url="https://rest.ase.kr/fsa/other_major_ratios" columns={columnInfosOtherMajorRatios} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </Card>
    )
}