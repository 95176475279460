import React from "react"

export default ({ label = "Badge", color = "", onRemoveClicked = () => { } }) => {

    return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10 text-nowrap">
            {color !== "" && (
                <div className="me-1">
                    <svg className="h-1.5 w-1.5" viewBox="0 0 6 6" aria-hidden="true" style={{ fill: color }}>
                        <circle cx={3} cy={3} r={3} />
                    </svg>
                </div>
            )}
            {label}
            <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-600/20" onClick={onRemoveClicked}>
                <span className="sr-only">Remove</span>
                <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
            </button>
        </span>
    )
}