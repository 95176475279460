import React, { useEffect, useState, useRef, forwardRef } from 'react';
import _ from 'lodash';
import { IChartingLibraryWidget, widget as TvWidget } from 'libs/charting_library';
import chartConfig from './config';

export default ({ exchange, ticker, dataFeed, onChartReady = (tvHandler: IChartingLibraryWidget) => { } }) => {
    const ref = useRef(null);
    const [tvWidget, setTvWidget] = useState({});

    useEffect(() => {
        console.log("[TvChart] Mounted")
        const widgetOptions = {
            ...chartConfig,
            symbol: _.isNil(dataFeed) ? "AAPL" : `${exchange}:${ticker}`,
            datafeed: dataFeed ?? new window["Datafeeds"].UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
            container: ref.current,
        };
        const tempTvWidget = new TvWidget(widgetOptions as any);
        tempTvWidget.onChartReady(() => onChartReady(tempTvWidget))
        ref["tvHandler"] = tempTvWidget;
        setTvWidget(tempTvWidget);
        return () => {
            console.log("[TvChart] Unmounted")
            tempTvWidget.remove();
        }
    }, [exchange, ticker])


    return (
        <div>
            <div ref={ref} style={{ height: "60vh" }} className={'TVChartContainer'} >
                TV Chart
            </div>
        </div>
    )
}