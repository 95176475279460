import React from "react"
import SideNav from "components/SideNav"

const menus = [
    // {
    //     title: "Home",
    //     subMenus: [

    //     ],
    //     to: "/home"
    // },
    {
        title: "종목정보",
        subMenus: [
            {
                title: "시세",
                to: "/stock/quote",
            },
            {
                title: "재무정보",
                to: "/stock/financial_info",
            },
        ]
    },
    {
        title: "시장동향",
        subMenus: [
            {
                title: "수익률",
                to: "/market/yield",
            },
            // {
            //     title: "거래량",
            //     to: "/market/volume",
            // },
        ]
    },
    {
        title: "재무제표",
        subMenus: [
            {
                title: "대차대조표",
                to: "/fsa/balance_sheet",
            },
            {
                title: "손익계산서",
                to: "/fsa/income_statement",
            },
        ]
    },
    {
        title: "비율분석",
        subMenus: [
            {
                title: "재무비율",
                to: "/fsa/financial_ratio",
            },
            {
                title: "수익성비율",
                to: "/fsa/profit_ratio",
            },
            {
                title: "안정성비율",
                to: "/fsa/stability_ratio",
            },
            {
                title: "성장성비율",
                to: "/fsa/growth_ratio",
            },
            {
                title: "기타주요비율",
                to: "/fsa/other_major_ratios",
            },
        ]
    },
    // {
    //   title: "Info",
    //   subMenus: [
    //     {
    //       title: "Token",
    //       to: "/info/token",
    //     },
    //     {
    //       title: "Pool",
    //       to: "/info/pool",
    //     },
    //     // {
    //     //     title: "Route",
    //     //     to: "/market/route",
    //     // },
    //   ]
    // },
    // {
    //     title: "Transaction",
    //     subMenus: [
    //         // {
    //         //     title: "Transfer",
    //         //     to: "/transaction/transfer",
    //         // },
    //         // {
    //         //     title: "Trade",
    //         //     to: "/transaction/trade",
    //         // },
    //         {
    //             title: "Trace",
    //             to: "/transaction/trace",
    //         },

    //     ]
    // },
    // {
    //   title: "Dashboard",
    //   subMenus: [
    //     {
    //       title: "Volume",
    //       to: "/dashboard/volume",
    //     },
    //   ]
    // },
    // {
    //     title: "Index",
    //     subMenus: [
    //         {
    //             title: "Volatility",
    //             to: "/index/volatility",
    //         },
    //     ]
    // },
    // {
    //     title: "Advanced",
    //     subMenus: [
    //         {
    //             title: "Nodes",
    //             to: "/advanced/nodes",
    //         },
    //     ]
    // },
    // {
    //     title: "Setting",
    //     subMenus: [
    //         {
    //             title: "Applications",
    //             to: "/setting/applications",
    //         },
    //     ]
    // },
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}