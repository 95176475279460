export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "cptl_ntin_rate",
        label: "총자본순이익율",
    },
    {
        field: "self_cptl_ntin_inrt",
        label: "자기자본순이익율",
    },
    {
        field: "sale_ntin_rate",
        label: "매출액순이익율",
    },
    {
        field: "sale_totl_rate",
        label: "매출액총이익율",
    }
]