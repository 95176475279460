
import React from "react";
import { Routes, Route } from "react-router-dom";

import BalanceSheetPage from "pages/FSA/BalanceSheet";
import IncomeStatementPage from "pages/FSA/IncomeStatement";
import FinancialRatioPage from "pages/FSA/FinancialRatio";
import ProfitRatioPage from "pages/FSA/ProfitRatio";
import OtherMajorRatiosPage from "pages/FSA/OtherMajorRatios";
import StabilityRatioPage from "pages/FSA/StabilityRatio";
import GrowthRatioPage from "pages/FSA/GrowthRatio";

export default () => (
    <Routes>
        <Route path="/balance_sheet" element={<BalanceSheetPage />}></Route>
        <Route path="/income_statement" element={<IncomeStatementPage />}></Route>
        <Route path="/financial_ratio" element={<FinancialRatioPage />}></Route>
        <Route path="/profit_ratio" element={<ProfitRatioPage />}></Route>
        <Route path="/other_major_ratios" element={<OtherMajorRatiosPage />}></Route>
        <Route path="/stability_ratio" element={<StabilityRatioPage />}></Route>
        <Route path="/growth_ratio" element={<GrowthRatioPage />}></Route>
    </Routes>
)

