export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "cras",
        label: "유동자산",
    },
    {
        field: "fxas",
        label: "고정자산",
    },
    {
        field: "total_aset",
        label: "자산총계",
    },
    {
        field: "flow_lblt",
        label: "유동부채",
    },
    {
        field: "fix_lblt",
        label: "고정부채",
    },
    {
        field: "total_lblt",
        label: "부채총계",
    },
    {
        field: "cpfn",
        label: "자본금",
    },
    {
        field: "cfp_surp",
        label: "자본잉여금",
        hidden: true,
    },
    {
        field: "prfi_surp",
        label: "이익잉여금",
        hidden: true,
    },
    {
        field: "total_cptl",
        label: "자본총계",
    }
]