export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "grs",
        label: "매출액증가율",
    },
    {
        field: "bsop_prfi_inrt",
        label: "영업이익증가율",
    },
    {
        field: "equt_inrt",
        label: "자기자본증가율",
    },
    {
        field: "totl_aset_inrt",
        label: "총자산증가율",
    }
]