import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { classNames } from 'components/uiUtils'

const exampleData = [
    { name: '4 GB' },
    { name: '8 GB' },
    { name: '16 GB' },
    { name: '32 GB' },
    { name: '64 GB' },
    { name: '128 GB' },
]

const Radio = ({ items, selectedItem, onChange = (item) => { } }) => {

    return (
        <div className="overflow-scroll">
            <RadioGroup value={selectedItem} onChange={onChange} className="">
                <div className="flex space-x-2">
                    {items.map((option) => (
                        <RadioGroup.Option
                            key={option.name}
                            value={option}
                            className={({ active, checked }) =>
                                classNames(
                                    active ? 'ring-2 ring-indigo-500 ring-offset-2' : '',
                                    // checked ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                                    checked
                                        ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold uppercase whitespace-nowrap',
                                    'cursor-pointer focus:outline-none'
                                )
                            }
                        >
                            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    )
}

export const useRadio = ({ items = exampleData }) => {
    const [selectedItem, setSelectedItem] = useState(items[0])

    return {
        Radio: () => <Radio items={items} selectedItem={selectedItem} onChange={setSelectedItem} />,
        selectedItem,
        setSelectedItem,
    }
}