import React, { useState, ForwardedRef, useEffect } from 'react';
import Card from 'components/Card';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import Input from 'components/Form/Input';
import { CustomDatePickerInput } from '..';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const EXAMPLE_DTS = [new Date('1970-01-01'), new Date('1970-01-02')];

export default ({ dts = EXAMPLE_DTS, onDtChanged = (dt: Date) => { }, ...otherProps }) => {
    const [selectedDt, setSelectedDt] = useState(dts[0]);

    useEffect(() => {
        setSelectedDt(dts[0]);
    }, [dts])

    const handleFirstButtonClick = () => {
        const firstDate = _.min(dts);
        setSelectedDt(firstDate);
    }

    const handlePreviousButtonClick = () => {
        const prevDate = _.max(dts.filter(dt => new Date(dt).getTime() < new Date(selectedDt).getTime()));
        if (!prevDate) return;
        setSelectedDt(prevDate);
    }

    const handleNextButtonClick = () => {
        const nextDate = _.min(dts.filter(dt => new Date(dt).getTime() > new Date(selectedDt).getTime()));
        if (!nextDate) return;
        setSelectedDt(nextDate);
    }

    const handleLastButtonClick = () => {
        const lastDate = _.max(dts);
        setSelectedDt(lastDate);
    }

    useEffect(() => {
        onDtChanged(selectedDt);
    }, [selectedDt])

    return (
        <div className="isolate inline-flex rounded-md shadow-sm z-20">
            <button
                type="button"
                className="relative -mr-px inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                onClick={handleFirstButtonClick}
            >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
                type="button"
                className="relative -mr-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                onClick={handlePreviousButtonClick}
            >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <DatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={selectedDt}
                onChange={dt => setSelectedDt(dt)}
                customInput={<CustomDatePickerInput />}
                showPopperArrow={false}
                includeDates={dts}
                {...otherProps}
            // renderCustomHeader={CustomHeader}
            ></DatePicker>
            <button
                type="button"
                className="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                onClick={handleNextButtonClick}
            >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
                type="button"
                className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                onClick={handleLastButtonClick}
            >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    )
}