import momentTz from "moment-timezone";

export const timestampToDtString = (dt) => momentTz(dt).tz("Asia/Seoul").format().replace("T", " ").replace("+09:00", "");

export const resolutionToMsec = (resolution) => {
    if (resolution === "1D") {
        return 24 * 60 * 60 * 1000;
    } else if (resolution === "1W") {
        return 24 * 60 * 60 * 1000;
    } else if (resolution === "1M") {
        return 31 * 24 * 60 * 60 * 1000;
    } else {
        return parseInt(resolution) * 60 * 1000;
    }
}