import React, { useEffect, useRef, useState } from "react"
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import { useTickerCombo } from "containers/Form/TickerCombo";
import Badges from "components/Badges";
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useComboBox } from "components/Form/Combobox";
import axios from "axios";
import _ from "lodash";
import { presetColors } from "assets/color/preset";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const parseTableData = (tableData, selectedTargetField) => {
    const sortedTableData = tableData.sort((a, b) => a.stac_yymm - b.stac_yymm);
    const selectedData = sortedTableData.map((elm) => Number(elm[selectedTargetField]));
    const labels = sortedTableData.map((elm) => elm.stac_yymm);
    // key map
    const data = labels.map((label, i) => (
        {
            label: label,
            data: selectedData[i]
        }
    ))
    return data;
}

const spanEmptyDatas = (labels, data) => {
    const spannedData = labels.map((label) => {
        const index = data.map((elm) => elm.label).indexOf(label);
        return index === -1 ? null : data[index].data;
    });
    return spannedData;
}

export default ({ tableData = [], columns = [], url = "" }) => {
    const chartRef = useRef<ChartJS<"line", number[], string>>(null);
    const { Modal, openModal, closeModal, isOpen } = useModal();
    const { Combobox: TickerCombo, selectedItem: selectedTicker } = useTickerCombo({ isExistBalanceSheet: true });
    const [selectedStockName, setSelectedStockName] = useState("");
    const [selectedData, setSelectedData] = useState([]);
    const [compareTableDatas, setCompareTableDatas] = useState({});
    const [compareDatas, setCompareDatas] = useState({});
    const [labels, setLabels] = useState([]);
    const [chartDataStruct, setChartDataStruct] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        console.log("FinancialInfo/Chart On Table Data Changed", tableData);
        const stockName = `${tableData[0]?.ticker} ${tableData[0]?.name}`;
        setSelectedStockName(stockName);
    }, [tableData])

    console.log("FinancialInfo/Chart", tableData, columns)
    const comboItems = columns
        .filter((elm) => !elm.hidden)
        .filter((elm) => !["결산년월"].includes(elm.label))
        .map((elm) => ({ name: elm.label, data: elm.field }));

    const { Combobox: TargetDataCombo, selectedItem: selectedTargetItem } = useComboBox({ items: comboItems });

    useEffect(() => {
        console.log("FinancialInfo/Chart On Combo Items Changed", selectedTargetItem);
        // setSelectedItem(comboItems[0]);
    }, [comboItems]);

    useEffect(() => {
        const parsedSelectedData = parseTableData(tableData, selectedTargetItem.data);
        setSelectedData(parsedSelectedData);
    }, [tableData, selectedTargetItem])

    useEffect(()=>{
        const tempCompareDatas = {};
        Object.keys(compareTableDatas).forEach((key) => {
            const tableData = compareTableDatas[key];
            const parsedData = parseTableData(tableData, selectedTargetItem.data);
            tempCompareDatas[key] = parsedData;
        })
        setCompareDatas(tempCompareDatas);
    }, [compareTableDatas, selectedTargetItem])

    useEffect(() => {
        const selectedLabels = selectedData.map((elm) => elm.label);
        const compareLabels = Object.keys(compareDatas).map((key) => compareDatas[key].map((elm) => elm.label)).flat();
        const totalLabels = _.uniq([...selectedLabels, ...compareLabels]);
        const sortedTotalLabels = totalLabels.sort((a, b) => a - b);
        setLabels(sortedTotalLabels);
        const tempDatasets = [];
        tempDatasets.push({
            data: spanEmptyDatas(sortedTotalLabels, selectedData),
            borderColor: 'rgba(102, 102, 255, 1)',
            spanGaps: true,
        })
        Object.keys(compareDatas).forEach((key, i) => {
            const compareData = compareDatas[key];
            tempDatasets.push({
                data: spanEmptyDatas(sortedTotalLabels, compareData),
                borderColor: presetColors[i % presetColors.length],
                spanGaps: true,
            })
        })
        setChartDataStruct({ labels: sortedTotalLabels, datasets: tempDatasets });
    }, [selectedData, compareDatas])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: true,
                // text: 'Chart.js Line Chart',
            },
        },
    };

    const onOpenModalButtonClicked = async () => {
        console.log("Button Clicked")
        // chartRef.current.data.datasets[0].data[3] = null;
        // console.log("DATAS", chartRef.current.data)
        // console.log(chartRef.current.data.datasets[0].data[0])
        // chartRef.current.update();
        openModal();
    }

    const onAddButtonClicked = async () => {
        console.log("Selected Ticker", selectedTicker);
        // if selectedTicker is same as selectedStockName, return
        if (selectedStockName === selectedTicker.name || compareTableDatas[selectedTicker.name] !== undefined) {
            alert("이미 선택된 종목입니다.");
            return;
        }
        const params = {
            ticker: selectedTicker.data
        }
        console.log("Stock Chart Request Params", params)
        const ret = await axios.get(url, { params });
        if (ret.data.length === 0) {
            console.log("[StockSimpleTable] No data found.");
            return;
        }
        console.log("@@@@", ret)
        setCompareTableDatas({ ...compareTableDatas, [selectedTicker.name]: ret.data });
        closeModal()
    }

    useEffect(()=>{
        // get all ticker's data from server
        updateCompareDatas();
    },[url])

    const updateCompareDatas = async () => {
        let tempCompareTableDatas = {};
        for await (const key of Object.keys(compareTableDatas)) {
            console.log("@@@@@@@ KEY", key)
            const ticker = key.split(" ")[0];
            const params = {
                ticker: ticker
            }
            console.log("Stock Chart Request Params", params)
            const ret = await axios.get(url, { params });
            if (ret.data.length === 0) {
                console.log("[StockSimpleTable] No data found.");
                return;
            }
            console.log("@@@@", ret)
            tempCompareTableDatas = { ...tempCompareTableDatas, [key]: ret.data };
        }
        setCompareTableDatas(tempCompareTableDatas);
    }

    const onRemoveClicked = (item) => {
        console.log("Remove Clicked", item);
        const tempCompareTableDatas = { ...compareTableDatas };
        delete tempCompareTableDatas[item];
        setCompareTableDatas(tempCompareTableDatas);
    }

    return (
        <div>
            <Modal parentSelector={() => document.querySelector('.main-content')}>
                <div className="flex">
                    <div className="min-w-48">
                        <TickerCombo />
                    </div>
                    <div className="ms-2 max-w-30">
                        <Button onButtonClicked={onAddButtonClicked}>추가</Button>
                    </div>
                </div>
            </Modal>

            <div className="ring-1 ring-black ring-opacity-5 sm:rounded-lg w-full mt-2 p-1">
                <div className="flex justify-between p-1">
                    <TargetDataCombo />
                    <div className="ms-2">
                        <Button onButtonClicked={onOpenModalButtonClicked}>비교종목추가</Button>
                    </div>
                </div>
                <div className="pt-1 px-2">
                    <Badges items={[...Object.keys(compareDatas)]} colors={
                        [...Object.keys(compareDatas)].map((key, i) => presetColors[i % presetColors.length])
                    } onRemoveClicked={onRemoveClicked} />
                </div>
                <div style={{ height: "40vh" }}>
                    <Line data={chartDataStruct} options={options} ref={chartRef} />
                </div>
            </div>
        </div >
    )
}