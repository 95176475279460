export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "lblt_rate",
        label: "부채비율",
    },
    {
        field: "bram_depn",
        label: "차입금의존도",
    },
    {
        field: "crnt_rate",
        label: "유동비율",
    },
    {
        field: "quck_rate",
        label: "당좌비율",
    }
]