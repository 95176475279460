export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "payout_rate",
        label: "배당성향",
        hidden: true
    },
    {
        field: "eva",
        label: "EVA",
    },
    {
        field: "ebitda",
        label: "EBITDA",
    },
    {
        field: "ev_ebitda",
        label: "EV_EBITDA",
    }
]