import React, { useEffect, useState } from "react"
import { useComboBox } from "components/Form/Combobox";
import { useSearchParams, } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

export const useTickerCombo = ({ isExistBalanceSheet = false }) => {
    const [stocks, setStocks] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();


    const { Combobox, selectedItem, setSelectedItem } = useComboBox({ items: stocks });

    const tickerFromUrl = searchParams.get("ticker");
    console.log("Ticker From URL", tickerFromUrl);

    useEffect(() => {
        updateTickers();
    }, []);

    useEffect(() => {
        if (selectedItem) {
            if (!_.isNil(selectedItem.data)) {
                setSearchParams({ ticker: selectedItem.data });
            }
        }
    }, [selectedItem])

    const updateTickers = async () => {
        const ret = await axios.get("https://rest.ase.kr/master/tickers", {});
        if (ret.data.length === 0) {
            console.log("[Stock] No data found.");
            return;
        }
        if (isExistBalanceSheet) {
            const balanceSheetDistinctRet = await axios.get("https://rest.ase.kr/fsa/distinct", { params: { collection: "balance_sheet", field: "ticker" } });
            const balanceSheetTickers = balanceSheetDistinctRet.data;
            const tempItems = ret.data.filter((elm) => balanceSheetTickers.includes(elm.code0)).map((elm) => ({ name: `${elm.code0} ${elm.name}`, ticker: elm.code0, data: elm.code0 }));
            //TODO REFACTORING
            const found = tempItems.find((elm) => elm.ticker === tickerFromUrl);
            console.log("Item Found", found);
            setStocks(tempItems);
            setSelectedItem(tempItems[0]);
            if (_.isNil(found)) {
                setSelectedItem(tempItems[0]);
                setSearchParams({ ticker: tempItems[0].ticker });
            } else {
                setSelectedItem(found);
            }
        } else {
            const tempItems = ret.data.map((elm) => ({ name: `${elm.code0} ${elm.name}`, ticker: elm.code0, data: elm.code0 }));
            //TODO REFACTORING
            const found = tempItems.find((elm) => elm.ticker === tickerFromUrl);
            console.log("Item Found", found);
            setStocks(tempItems);
            if (_.isNil(found)) {
                setSelectedItem(tempItems[0]);
                setSearchParams({ ticker: tempItems[0].ticker });
            } else {
                setSelectedItem(found);
            }
        }
    }

    const ticker = _.find(stocks, { name: selectedItem.name })?.ticker;

    return { Combobox, selectedItem, ticker };
}