import React from 'react';

const Card = (props) => {
    return (
        <div className="flex py-2 align-middle px-2 lg:px-2">
            <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg w-full">
                {props.children}
            </div>
        </div>

    )
}

export default Card;