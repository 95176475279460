export const columnInfos = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "stac_yymm",
        label: "결산년월",
    },
    {
        field: "grs",
        label: "매출액증가율",
    },
    {
        field: "bsop_prfi_inrt",
        label: "영업이익증가율",
    },
    {
        field: "ntin_inrt",
        label: "순이익증가율",
    },
    {
        field: "roe_val",
        label: "ROE",
    },
    {
        field: "eps",
        label: "EPS",
    },
    {
        field: "sps",
        label: "주당매출액",
    },
    {
        field: "bps",
        label: "BPS",
    },
    {
        field: "rsrv_rate",
        label: "유보비율",
    },
    {
        field: "lblt_rate",
        label: "부채비율",
    }
]