import React, { useEffect, useState } from "react";
import Card from "components/Card";
import axios from "axios";
import { useRadio } from "components/Form/Radio/Card";
import Input from "components/Form/Input";
import SimpleTable from "components/Table/Simple";
import { useDatePicker } from "components/Form/Datepicker";
import _ from "lodash";

const INTERVAL_ITEMS = [
    { name: "3일" },
    { name: "1주" },
    { name: "1개월" },
    { name: "3개월" },
    { name: "6개월" },
    { name: "1년" },
    { name: "3년" },
    { name: "5년" },
    // { name: "전체" },
    { name: "사용자지정" }
]

const columns = [
    {
        field: "ticker",
        label: "종목코드",
    },
    {
        field: "name",
        label: "종목명",
    },
    {
        field: "start",
        label: "기준가",
    },
    {
        field: "end",
        label: "비교가",
    },
    {
        field: "yield",
        label: "수익률",
    }
]

// array of 1 year = 365 days
const DTS = Array.from({ length: 365 }, (_, i) => new Date(new Date().setDate(new Date().getDate() - i)));

export default ({ title }) => {
    const { Radio: IntervalRadio, selectedItem: selectedInterval, setSelectedItem: setSelectedInterval } = useRadio({ items: INTERVAL_ITEMS })
    // const [fromDt, setFromDt] = useState("")
    // const [toDt, setToDt] = useState("")
    const [yields, setYields] = useState([])
    const [dts, setDts] = useState([new Date()]);
    const { DatePicker: StartDatePicker, selectedDt: selectedFromDt, setSelectedDt: setFromDt } = useDatePicker({ label: "기준일", dts, onDtChanged: (date) => console.log("Date Changed", date) })
    const { DatePicker: EndDatePicker, selectedDt: selectedToDt, setSelectedDt: setToDt } = useDatePicker({ label: "비교일", dts, onDtChanged: (date) => console.log("Date Changed", date) })

    useEffect(() => {
        updateDts();
    }, [])

    const updateDts = async () => {
        const ret = await axios.get("https://rest.ase.kr/market/dates");
        // console.log("Market Dates", ret.data);
        // format yyyymmdd to Date
        const tempDts = ret.data.map((yymmdd) => new Date(`${yymmdd.slice(0, 4)}-${yymmdd.slice(4, 6)}-${yymmdd.slice(6, 8)}`));
        // filter 5 years
        const filteredDts = tempDts.filter((dt) => dt.getFullYear() >= new Date().getFullYear() - 5);
        setDts(filteredDts);
    }

    useEffect(() => {
        const fromDt = new Date();
        const toDt = new Date();
        if (selectedInterval.name === "3일") {
            fromDt.setDate(fromDt.getDate() - 3);
        } else if (selectedInterval.name === "1주") {
            fromDt.setDate(fromDt.getDate() - 7);
        } else if (selectedInterval.name === "1개월") {
            fromDt.setMonth(fromDt.getMonth() - 1);
        } else if (selectedInterval.name === "3개월") {
            fromDt.setMonth(fromDt.getMonth() - 3);
        } else if (selectedInterval.name === "6개월") {
            fromDt.setMonth(fromDt.getMonth() - 6);
        } else if (selectedInterval.name === "1년") {
            fromDt.setFullYear(fromDt.getFullYear() - 1);
        } else if (selectedInterval.name === "3년") {
            fromDt.setFullYear(fromDt.getFullYear() - 3);
        } else if (selectedInterval.name === "5년") {
            fromDt.setFullYear(fromDt.getFullYear() - 5);
        } else if (selectedInterval.name === "사용자지정") {
            return;
        }
        // select lte in dts
        const fromDtAvailable = _.last(dts.filter((dt) => dt <= fromDt));
        const toDtAvailable = _.last(dts.filter((dt) => dt <= toDt));
        if(_.isNil(fromDtAvailable) || _.isNil(toDtAvailable)){
            return;
        }
        setFromDt(fromDtAvailable);
        setToDt(toDtAvailable);
        // updateData(fromYymmdd, toYymmdd);
    }, [dts, selectedInterval])

    const updateData = async () => {
        const from = `${selectedFromDt.getFullYear()}${String(selectedFromDt.getMonth() + 1).padStart(2, '0')}${String(selectedFromDt.getDate()).padStart(2, '0')}`;
        const to = `${selectedToDt.getFullYear()}${String(selectedToDt.getMonth() + 1).padStart(2, '0')}${String(selectedToDt.getDate()).padStart(2, '0')}`;
        console.log("From To", from, to);
        if (from >= to) {
            console.log("From date should be earlier than To date.")
            setYields([]);
            return;
        }
        const params = {
            from,
            to
        }
        const ret = await axios.get("https://rest.ase.kr/market/yield", { params });
        if (ret.data.length === 0) {
            console.log("[MarketVolume] No data found.");
            return;
        }
        const { from: tempFromDt, to: tempToDt, yields: tempYields } = ret.data;
        // yyyyMMdd to Date
        const fromDtDate = new Date(`${tempFromDt.slice(0, 4)}-${tempFromDt.slice(4, 6)}-${tempFromDt.slice(6, 8)}`);
        const toDtDate = new Date(`${tempToDt.slice(0, 4)}-${tempToDt.slice(4, 6)}-${tempToDt.slice(6, 8)}`);
        // setFromDt(fromDtDate);
        // setToDt(toDtDate);
        setYields(tempYields.map((elm) => ({ ...elm, yield: `${Number(elm.yield).toFixed(2)}` })));
        console.log("MarketVolume Data", ret.data);
    }

    useEffect(() => {
        updateData();
    }, [selectedFromDt, selectedToDt])

    return (
        <Card>
            <div className="p-3">
                <h6 className="ps-1 pe-3 font-bold self-center text-lg text-nowrap">{title}</h6>
                <div className="mt-2">
                    <IntervalRadio />
                </div>
                <div className="mt-2 flex overflow-scroll">
                    <div className="w-full me-2 z-20">
                        {/* <Input label="기준일" value={fromDt} disabled={true}></Input> */}
                        <StartDatePicker dateFormat={"yyyy-MM-dd"} onClick={()=>{setSelectedInterval(_.last(INTERVAL_ITEMS))}}/>
                    </div>
                    <div className="w-full z-20">
                        {/* <Input label="비교일" value={toDt} disabled={true}></Input> */}
                        <EndDatePicker dateFormat={"yyyy-MM-dd"} onClick={()=>{setSelectedInterval(_.last(INTERVAL_ITEMS))}}/>
                    </div>
                </div>
                <div className="mt-2">
                    <SimpleTable data={yields} columns={columns}></SimpleTable>
                </div>
            </div>
        </Card>
    )
}