import React from 'react';
import _ from 'lodash';
import { useState } from 'react';
import TvChart from 'components/Chart/TvChart';
import { widget as TvWidget, IChartWidgetApi, IChartingLibraryWidget } from 'libs/charting_library';
import CustomDatafeed from './datafeed';

export default ({ ticker }) => {
    return (
        <TvChart exchange="KRX" ticker={ticker} dataFeed={{ ...CustomDatafeed }} />
    )
}