import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useTable } from "../Core";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const SimpleTable = ({ data, columns = [] }) => {
    const table = useTable({ data: data, columns: columns });
    const tableRef = useRef(null);
    const [endIndex, setEndIndex] = useState(100);

    useEffect(() => {
        console.log("Use Effect Table Data")
        setEndIndex(100);
    }, [table.getData()])

    const onScroll = () => {
        console.log("On Scroll")
        const element = tableRef.current;
        const scrollTop = element.scrollTop;
        // console.log("scrollTop", scrollTop)
        const scrollHeight = element.scrollHeight;
        // console.log("scrollHeight", scrollHeight)
        const clientHeight = element.clientHeight;
        // console.log("clientHeight", clientHeight)
        // when scroll reaches bottom
        if (scrollTop + clientHeight * 2 >= scrollHeight) {
            console.log("Reached bottom")
            setEndIndex(endIndex + 100);
        }
    }

    return (
        <div className="align-middle">
            <div className="shadow ring-1 ring-black ring-opacity-5 max-h-[80vh] overflow-y-auto bg-white" onScroll={onScroll} ref={tableRef}>
                <table className="border-separate border-spacing-0 min-w-full">
                    <thead className="bg-gray-50 text-nowrap">
                        <tr className="divide-x divide-gray-200">
                            {table.getFields().map((fieldName: string, i) => {
                                const columnInfo = _.find(columns, (info) => info.field === fieldName);
                                const fieldLabel = !_.isNil(columnInfo) ? columnInfo?.label ?? fieldName : fieldName;
                                return (
                                    <th scope="col" className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-2 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        key={i}
                                        title={fieldName}
                                        onClick={() => {
                                            table.sortData(fieldName)
                                        }}>{fieldLabel}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {table.getData().filter((_, i) => i <= endIndex).map((row, i) => (
                            <tr key={i} className="divide-x divide-gray-200">
                                {table.getFields().map((fieldName: string, i) => (
                                    <td className="whitespace-nowrap py-1.5 pl-4 pr-4 text-sm text-gray-900"
                                        key={`col_${i}`}>
                                        {String(row[fieldName])}
                                    </td>
                                ))}
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SimpleTable;
