import React from "react";
import "./style.css";
import logo from 'assets/logo/logo-no-background.png';
import Menu from './Menu';

const SideNav = ({ menus = [] }) => {
    return (
        <div className="sidenav-container">
            <div className="sidenav-spacer"></div>
            <input id="toggle-sidenav" type="checkbox" role="button" />
            <label className="sidenav-hamburger rounded-md bg-white py-2 px-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" htmlFor="toggle-sidenav">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </label>
            <label className="sidenav-dimmed" htmlFor="toggle-sidenav"></label>
            <div className="sidenav-content">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6" style={{ height: "100%" }}>
                    <div className="flex items-center">
                        <img className="w-auto p-3 mt-5" src={logo} alt="Defimix"></img>
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1 text-nowrap">
                                    {menus.map((menu, i) => (<Menu key={i} menu={menu}></Menu>))}
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default SideNav;
