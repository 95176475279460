import React, { useState, useEffect, useMemo } from "react"
import Card from "components/Card"
import Input from "components/Form/Input";
import SimpleTable from "components/Table/Simple";
import Button from "components/Form/Button";
import Badges from "components/Badges";
import DateWithStep from "components/Form/Datepicker/DateWithStep";
import axios from "axios";
import _, { set } from "lodash";

export default ({ title, url, columnInfos }) => {
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [dts, setDts] = useState([new Date()]);
    const [selectedDt, setSelectedDt] = useState(dts[0]);

    useEffect(() => {
        updateDts();
    }, [filters])

    const updateDts = async () => {
        console.log("Update Dts");
        const splittedUrl = url.split("/");
        const collection = splittedUrl[splittedUrl.length - 1];
        const params = {
            collection,
            field: "stac_yymm",
            filters
        }
        const rawRet = await axios.get("https://rest.ase.kr/fsa/distinct", { params });
        // const rawRet = await axios.get("http://localhost:5050/fsa/distinct", { params });
        const yyyymms = rawRet.data;
        if (_.isEmpty(yyyymms)) return;
        const tempDts = yyyymms.sort().reverse().map((yymm) => new Date(`${yymm.slice(0, 4)}-${yymm.slice(4, 6)}-01`));
        setDts(tempDts);
    }

    useEffect(() => {
        updateData();
    }, [selectedDt])


    const updateData = async () => {
        console.log("Update Data Of Selected Dt", selectedDt)
        // selectedDt to YYYYMM
        const yyyymm = `${selectedDt.getFullYear()}${String(selectedDt.getMonth() + 1).padStart(2, '0')}`;
        const params = {
            stac_yymm: yyyymm
        }
        const ret = await axios.get(url, { params });
        if (_.isEmpty(ret.data)) {
            console.log("No data found.");
            return;
        }
        const tempTableData = ret.data.map((elm) => (_.omit(elm, ['_id', "createdAt"])));
        setTableData(tempTableData);
    }

    const onAddButtonClicked = () => {
        console.log("Add Button Clicked", searchText);
        if (filters.includes(searchText)) return;
        setSearchText("");
        setFilters([...filters, searchText]);
    }

    const onBadgeRemoveClicked = (item: string) => {
        console.log("Badge Remove Clicked", item)
        const tempFilters = filters.filter((filter) => filter !== item);
        setFilters(tempFilters);
    }

    useEffect(() => {
        // if one of filter included in some values, include
        if (_.isEmpty(filters)) {
            setFilteredData(tableData);
            return;
        }
        const tempFilteredData = tableData.filter((data) => {
            return filters.some((filter) => {
                return Object.values(data).some((value) => {
                    return String(value).toLowerCase().includes(filter.toLowerCase());
                });
            });
        });
        setFilteredData(tempFilteredData);
    }, [tableData, filters])

    return (
        <Card>
            <div className="p-3">
                <div className="flex justify-between">
                    <h6 className="ps-1 pe-3 font-bold self-center text-lg text-nowrap">{title}</h6>
                    <div className="overflow-auto">
                        <div className="min-w-fit">
                            <div className="max-w-min p-1">
                                <div className="flex justify-end">
                                    <DateWithStep
                                        dts={dts}
                                        onDtChanged={setSelectedDt}
                                        dateFormat="yyyy-MM"
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex justify-end mt-2">
                                    <div className="me-1">
                                        <Input placeholder="필터" value={searchText} onChange={setSearchText}></Input>
                                    </div>
                                    <div className="pb-0">
                                        <Button onButtonClicked={onAddButtonClicked}>추가</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!_.isEmpty(filters) && (
                    <div className="mt-1">
                        <Badges items={filters} onRemoveClicked={onBadgeRemoveClicked}></Badges>
                    </div>
                )}
                {useMemo(() => (
                    <div className="mt-2">
                        <SimpleTable data={filteredData} columns={columnInfos}></SimpleTable>
                    </div>
                ), [filteredData])}
            </div>
        </Card>
    )
}