import React from "react"
import Badge from "./Badge"

export default ({ items = [], colors = [], onRemoveClicked = (item: string) => { } }) => {

    return (
        <div className="flex overflow-scroll">
            {items.map((item, i) => (
                <div className="me-2" key={i}>
                    <Badge label={item} key={i} onRemoveClicked={() => { onRemoveClicked(item) }} color={colors[i] ?? ""} />
                </div>
            ))}
        </div>
    )
}