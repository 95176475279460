import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Home from "pages/Home";
import FSARoutes from "containers/SiteRoute/FSA";
import StockRoutes from "containers/SiteRoute/Stock";
import MarketRoutes from "containers/SiteRoute/Market";
export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/home/'} />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/stock/*" element={<StockRoutes />}></Route>
            <Route path="/market/*" element={<MarketRoutes />}></Route>
            <Route path="/fsa/*" element={<FSARoutes />}></Route>
        </Routes>
    )
}