import React from 'react';
import Card from 'components/Card';
// import TvChart from 'components/Chart/TvChart';
import Candle from 'pages/Stock/Quote/Candle';
import { useTickerCombo } from 'containers/Form/TickerCombo';

export default () => {
    const { Combobox, ticker } = useTickerCombo({});

    return (
        <Card>
            <div className="p-3">
                <div className="flex justify-between mb-2">
                    <h6 className="font-bold font-lg self-center text-nowrap pe-3">시세</h6>
                    <Combobox />
                </div>
                <Candle ticker={ticker} />
            </div>
        </Card>
    )
}