import { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';

const useTable = ({ data, columns }) => {
    // const temp = data;
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        setTableData(data);
    }, [data])

    const getData = () => {
        return tableData
    };

    const getFields = () => _.uniq(_.flatten(tableData
        .map((row: string) =>
            Object.keys(row).filter(((fieldName: string) => columns.find((column: any) => column.field === fieldName)?.hidden !== true))
        )
    ))

    const sortData = (field) => {
        console.log("SORT")
        const sortedData = tableData.sort((elmA, elmB) => {
            if (typeof Number(elmA[field]) === 'number' && !_.isNaN(Number(elmA[field]))) {
                // console.log("@@@@@ NUMBER SORT", Number(elmA[field]))
                return Number(elmA[field]) < Number(elmB[field]) ? 1 : -1
            } else {
                // console.log("@@@@@ STRING SORT", Number(elmA[field]))
                return elmA[field].localeCompare(elmB[field])
            }
        });
        console.log(sortedData);
        const tempTableData = [...sortedData];
        setTableData(tempTableData);
    };

    return { getData, getFields, sortData };
}

export { useTable };