
import React from "react";
import { Routes, Route } from "react-router-dom";

import QuotePage from "pages/Stock/Quote";
import FinancialInfoPage from "pages/Stock/FinancialInfo";

export default () => (
    <Routes>
        <Route path="/quote" element={<QuotePage />}>
            <Route path=":tabId" element={<QuotePage />}></Route> 
        </Route>
        <Route path="/financial_info" element={<FinancialInfoPage />}>
            <Route path=":tabId" element={<FinancialInfoPage />}></Route> 
        </Route>
    </Routes>
)

