
import React from "react";
import { Routes, Route } from "react-router-dom";

import YieldPage from "pages/Market/Yield";
import VolumePage from "pages/Market/Volume";

export default () => (
    <Routes>
        <Route path="/yield" element={<YieldPage />}></Route>
        <Route path="/volume" element={<VolumePage />}></Route>
    </Routes>
)

