export const presetColors = [
    "rgba(255, 102, 102, 1)",  // 밝은 빨간색
    "rgba(255, 153, 102, 1)",  // 밝은 주황색
    "rgba(255, 240, 0, 1)",  // 밝은 노란색
    "rgba(102, 255, 102, 1)",  // 밝은 녹색
    "rgba(102, 153, 255, 1)",  // 연한 파란색
    "rgba(153, 102, 255, 1)",  // 연한 자주색
    "rgba(153, 255, 102, 1)",  // 밝은 녹색
    "rgba(102, 255, 255, 1)",   // 청록색
    "rgba(255, 102, 255, 1)",  // 밝은 자주색
    "rgba(128, 128, 128, 1)",  // 회색
]