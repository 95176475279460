import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash';
import { classNames } from 'components/uiUtils';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'

import "./style.scss";

const exampleData: {
    name: string;
    data?: any;
}[] = [
        { name: 'Leslie Alexander' },
        { name: 'Michael Foster' },
        { name: 'Dries Vincent' },
        { name: 'Lindsay Walton' },
    ]

const Combo = ({ items = exampleData, selectedItem = exampleData[0], onChange = (item) => { } }) => {
    const [query, setQuery] = useState('')
    const [limit, setLimit] = useState(100);

    const filteredItems = items.filter((item) => query === '' ? true : item.name.toLowerCase().includes(query.toLowerCase()))
    const limitedItems = filteredItems.filter((elm, i) => i < limit);

    const onSelectedItemChanged = (event) => {
        console.log("onSelectedItemChanged", event)
        onChange(event)
    }

    return (
        <Combobox as="div" value={selectedItem} onChange={onSelectedItemChanged}>
            <div className="relative">
                <Combobox.Button className="relative w-full">
                    <input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        // onChange={(event) => setQuery(event.target.value)}
                        // displayValue={(item: any) => item?.name}
                        // disabled={true}
                        value={selectedItem?.name}
                        readOnly={true}
                    >
                        {/* item?.name */}
                    </input>
                    <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </Combobox.Button>
                {items.length > 0 && (
                    <Combobox.Options className="absolute z-20 mt-1 max-h-100 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" onScroll={() => { console.log("ONSCROLL") }}>
                        <div className="p-1">
                            <Combobox.Input
                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(event) => { event.preventDefault(); event.stopPropagation(); setQuery(event.target.value) }}
                                placeholder='Search...'
                            // displayValue={(item: any) => item?.name}
                            />
                        </div>
                        <div className="listOptions">
                            {limitedItems
                                .map((item, i) => (
                                    <Combobox.Option
                                        key={i}
                                        value={item}
                                        className={({ active }) =>
                                            classNames(
                                                'relative cursor-default select-none py-2 pl-3 pr-9 left-0',
                                                active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                            )
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>
                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            active ? 'text-white' : 'text-indigo-600'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            {filteredItems.length >= limit && (
                                <Combobox.Option
                                    key={"read_more"}
                                    value={"read_more_value"}
                                    className={({ active }) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9 left-0',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                        )
                                    }
                                    onClick={(e) => { e.preventDefault(); setLimit(limit + 100); console.log("LIMIT", limit); }}
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <span className={classNames('block truncate italic', selected && 'font-semibold')}>{`Load More(${filteredItems.length - limit})...`}</span>
                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            )}
                        </div>
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    )
}

const EMPTY_ITEM = { name: "Empty Data", data: null };

export const useComboBox = ({ items = exampleData }) => {
    const [selectedItem, setSelectedItem] = useState(items[0] ?? EMPTY_ITEM);

    useEffect(() => {
        if (items.map((item) => item.name).includes(selectedItem.name)) return;
        setSelectedItem(items[0] ?? EMPTY_ITEM);
    }, [items])

    return {
        Combobox: useMemo(() => () => <Combo items={items} selectedItem={selectedItem} onChange={setSelectedItem} />, [items, selectedItem]),
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,
    }
};


export default Combo
