import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Modal from 'react-modal';

export const defaultStyles: React.CSSProperties | any = {
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        position: 'absolute',
        display: 'flex',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90vw",
        maxWidth: "fit-content",
        maxHeight: "fit-content",
        overflow: 'visible',
    },
}

export const useModal = (styles = defaultStyles) => {
    const [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        console.log("Close")
        setIsOpen(false);
    }

    function openModal() {
        console.log("Open");
        setIsOpen(true);
    }
    return {
        Modal: ({ children, parentSelector = () => document.body }) =>
        (
            <div>
                <Modal
                    isOpen={isOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={styles}
                    contentLabel="Example Modal"
                    key="simpleModal"
                    ariaHideApp={false}
                    parentSelector={parentSelector}
                >
                    {children}
                </Modal>
            </div>
        ),
        closeModal,
        openModal,
        isOpen
    }
}

export default { useModal };
